import {Controller} from '@hotwired/stimulus';
import swal from 'sweetalert2'
import IMask from 'imask';
import Toastify from 'toastify-js';
import 'bootstrap';



export function enableElementLoading(button) {
    let content = button.innerHTML;
    button.innerHTML = '<div class="spinner-container"><div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div></div>';
    disableButton(button);
    return content;
}

export function disableElementLoading(button, content) {
    button.innerHTML = content;
    enableButton(button);
}

export function toast(message, type="bg-danger", close=true, duration=3000){
    Toastify({
        text: message,
        className: type,
        duration: duration,
        close: close,
        escapeMarkup: false,
    }).showToast();

}


export function alert(message, type="error", text=null){
    let params = {
        title: message,
        type: type,
        showCancelButton: false,
    };
    if(text !== null){
        params["text"] = text
    }
    swal(params)
}

export const msgbox = (element, message, error=true) => {
    element.style.visibility = "hidden"
    element.innerHTML = message
    element.classList.remove("alert","alert-danger","alert-success")
    if(error === true){
        element.classList.add("alert","alert-danger")
    } else {
        element.classList.add("alert","alert-success")
    }
    element.style.visibility = "visible"
};

export function enableButton(button){
    console.log("Enable button", button)
    button.disabled = false
}

export function disableButton(button){
    button.disabled = true
}

export function emptyForm(form){
    let inputs = form.querySelectorAll(".form-control, .form-check-input")
    for (let input = 0; input < inputs.length; input++) {
        inputs[input].value = ""
        inputs[input].removeAttribute("checked")
        console.log(input, inputs[input])
    }
}

export function fetchData(path, blocTarget, append = false){
    console.log(blocTarget);
    fetch(path, { method: 'GET' })
        .then(Result => Result.json())
        .then(data => {
            let response = data.data;
            if(response.error){
                blocTarget.innerHTML = response.error
            } else {
                if (append) {
                    blocTarget.innerHTML += response.content
                } else {
                    blocTarget.innerHTML = response.content
                }
            }
            loadImages()
        })
        .catch(errorMsg => {
            console.log(errorMsg)
        });
}

export function loadImages() {
    const observer = new IntersectionObserver(function(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                if (entry.target.getAttribute("invisible") !== null) {
                    entry.target.removeAttribute("invisible")
                    entry.target.setAttribute("visible","")
                }
                if (!entry.target.classList.contains("img-fluid")) {
                    entry.target.classList.add("img-fluid")
                }
                if (entry.target.getAttribute("data-src")) {
                    let urlToAssign = entry.target.getAttribute("data-src")
                    entry.target.setAttribute("src", urlToAssign)
                    entry.target.removeAttribute("data-src")
                    if (entry.target.getAttribute("blur") !== null) {
                        entry.target.removeAttribute("blur")
                        entry.target.setAttribute("visible","")
                    }
                }
            }
        });
    }, { threshold: [0.01, 0.5, 0.5, 1] });

    let imagesContent = document.getElementsByTagName('body')[0].getElementsByTagName("img")
    for (let i = 0, len = imagesContent.length; i < len; i++) {
        observer.observe(imagesContent[i])
    }
}

export function loadAnimations() {
    const observer = new IntersectionObserver(function(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting === true) {
                if (entry.target.getAttribute("invisible") !== null && entry.target.getAttribute("data-animation") !== null) {
                    let delay = 0;
                    if(entry.target.getAttribute("data-delay") !== undefined){
                        delay = parseInt(entry.target.getAttribute("data-delay"))
                    }
                    setTimeout(function(){
                        entry.target.removeAttribute("invisible")
                        entry.target.setAttribute("visible","")
                        entry.target.classList.add(entry.target.getAttribute("data-animation"))
                    },delay);
                }
            }
        });
    }, { threshold: [0.1, 0.1, 0.1] });

    let divsContent = document.getElementsByClassName("animate__animated")
    for (let i = 0, len = divsContent.length; i < len; i++) {
        observer.observe(divsContent[i])
    }
}

export function iframe_responsive(elem){
    const iframes = document.querySelectorAll(elem)
    console.log(iframes)
    Array.prototype.forEach.call(iframes, function(element) {
        let parent = element.parentElement;
        if(!parent.classList.contains("embed-responsive")){
            let wrapper = document.createElement('div')
            wrapper.classList.add('embed-responsive')
            wrapper.classList.add('embed-responsive-16by9')
            wrapper.append(element.cloneNode(true));
            element.replaceWith(wrapper);
        }
        if(!element.classList.contains("embed-responsive-item")){
            element.classList.add("embed-responsive-item")
        }
        element.classList.add("loaded")
        return element
    });
}

export function checkImagesSources() {
    console.log("imagesSources")

    let types = ["Webp", "Avif"];
    let images = document.querySelectorAll("img");


    images.forEach((image) => {
        let source = image.parentNode.querySelector("source");
        types.forEach((type) => {
            if ("src"+type.charAt(0).toUpperCase() + type.slice(1) in image.dataset) {
                if (image.complete) {
                    fetch(image.dataset["src"+type.charAt(0).toUpperCase() + type.slice(1)]);
                } else {
                    image.addEventListener("load", () => {
                        let convertImage = new Image();
                        convertImage.onload = () => {
                            image.src = convertImage.src;
                        }
                        convertImage.src = image.dataset["src"+type.charAt(0).toUpperCase() + type.slice(1)]
                        delete image.dataset["src"+type.charAt(0).toUpperCase() + type.slice(1)]

                    })
                }
            }
        })
    })
}



export default class extends Controller {

    static targets = ["bloc","button","results","submit","sentence"]
    static values = {
        url: String
    }

    getDataDynamical(){
        if(this.urlValue === undefined){
            console.log("Route introuvable")
        } else {
            let buttonCall = this.buttonTarget
            disableButton(buttonCall)
            fetch(this.urlValue, {method: 'GET'})
                .then(Result => Result.json())
                .then(data => {
                    let response = data.data
                    if (response.error) {
                        alert(response.error, "error");
                    } else {
                        if (response.target) {
                            if(!document.getElementById(response.target.targetID)){
                                document.body.innerHTML = document.body.innerHTML + '<div id="'+response.target.targetID+'"></div>'
                            }
                            document.getElementById(response.target.targetID).innerHTML = response.target.targetContent
                        }
                    }
                    loadImages()
                    enableButton(buttonCall)
                })
                .catch(errorMsg => {
                    enableButton(buttonCall)
                    console.log(errorMsg)
                });
        }
    }

    submitForm(data) {
        data.preventDefault()
        let formSubmit = data.target
        let path = formSubmit.getAttribute("data-path")
        if (path === undefined){
            alert("La route est introuvable")
        } else {
            formSubmit.classList.remove('was-validated')
            this.submitTarget.disabled = true
            fetch(path, { method: 'POST', body: new FormData(formSubmit) })
                .then(Result => Result.json())
                .then(data => {
                    let response = data.data
                    if(response.error){
                        if(this.hasResultsTarget){
                            toast(response.error);
                        } else{
                            alert(response.error,"error");
                        }
                    } else{
                        if(response.message){
                            if(this.hasResultsTarget){
                                toast(response.message,"bg-success", false);
                            } else{
                                alert(response.message,"success");
                            }
                        }
                        if(response.content){
                            document.getElementById(response.content.divID).innerHTML = response.content.divContent
                            loadImages()
                        }
                        if(response.animate){
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        }
                        if(response.redirect){
                            let timer = 0
                            if(response.redirect.timer !== null){
                                timer = response.redirect.timer
                            }
                            setTimeout(function(){
                                window.location = response.redirect.url
                            },timer);
                        }
                        emptyForm(formSubmit)
                    }
                    this.submitTarget.disabled = false
                })
                .catch(errorMsg => {
                    this.submitTarget.disabled = false
                });
        }
    }

    triggerPassword(element){
        if(element.getAttribute("data-target") !== undefined){
            let target = element.getAttribute("data-target")
            element.addEventListener("click", function(e){
                if(document.getElementById(target).getAttribute("type") === "text"){
                    document.getElementById(target).setAttribute("type", "password")
                } else{
                    document.getElementById(target).setAttribute("type", "text")
                }
            });
        }
    }

    scrollerEvent() {
        let btn = document.querySelectorAll("button.bloc_page_menu_link");
        btn.forEach((elem) => {
            if (elem.dataset.href) {
                elem.addEventListener("click" ,()=> {
                    let target = document.querySelector(elem.dataset.href)
                    if (target) {
                        let rect = target.getBoundingClientRect();
                        window.scrollTo({
                            top: rect.top - 150,
                            left: 0,
                            behavior: "smooth"
                        })
                    }

                })
            }

        })
    }

    connect() {

        this.scrollerEvent();

        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

        checkImagesSources()

        // Pour les retailles Images
        loadImages()

        //Chargement des animations
        loadAnimations()

        // Iframe responsive
        iframe_responsive("#content iframe, #content .googlemapsembed, #content .youtube_player");

        // Cookie Retaille Img
        document.cookie = "windows_width_height=" + document.body.clientWidth + "x" + document.body.clientHeight + "; expires=3600; path=/";

        // Mask pour les types tel
        const phonesInput = document.getElementsByClassName("phone-mask")
        Array.prototype.forEach.call(phonesInput, function(element) {
            IMask(
                element, {
                    mask: '00 00 00 00 00'
                }
            );
        });

        // Trigger pour les mots de passe
        let elementsPass = document.getElementsByClassName("togglePass")
        if(elementsPass.length > 0){
            for (let i = 0, len = elementsPass.length; i < len; i++) {
                this.triggerPassword(elementsPass[i])
            }
        }

        // Contenu AJAX dynamique
        if(this.hasBlocTarget){
            for (let i = 0, len = this.blocTargets.length; i < len; i++) {
                if(this.blocTargets[i].getAttribute("data-path") !== undefined){
                    fetchData(this.blocTargets[i].getAttribute("data-path"), this.blocTargets[i])
                    console.log(this.blocTargets[i], this.blocTargets[i].getAttribute("data-path"));
                }
            }
        }




    }
}