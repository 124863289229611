import { Controller } from '@hotwired/stimulus';
import Glide from '@glidejs/glide';
import "@glidejs/glide/src/assets/sass/glide.core.scss";
import "@glidejs/glide/src/assets/sass/glide.theme.scss";
//import "Scss/modules/properties/_propertiesHook.scss";

export default class extends Controller {

    connect() {
        super.connect();

        let glide = new Glide("#properties-glide", {
            type: "carousel",
            gap: 18,
            startAt: 0,
            perView: 4,
            autoplay: 2000,
            hoverPause: true,
            breakpoints: {
                1600: {
                    perView: 3
                },
                1400 : {
                    perView: 2
                },
                1200 : {
                    perView: 3
                },
                800 : {
                    perView: 3
                },
                600 : {
                    perView: 3,
                    gap:17
                },

            }
        });
        glide.mount();
    }



}