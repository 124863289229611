import { Controller } from '@hotwired/stimulus';
import { Modal } from "bootstrap";
import { toast } from "../core_controller";
import "Scss/modules/share/_share.scss";


export function shareBtnEventAdder() {

    let facebookUrlShare = "https://www.facebook.com/sharer/sharer.php?u={{URL}}";
    let linkedinUrlShare = "https://www.linkedin.com/shareArticle?mini=true&url={{URL}}"

    let modal = document.querySelector("#share-modal");
    if (modal !== null) {
        modal = new Modal(document.querySelector("#share-modal"));
        document.querySelectorAll("button.btn-share").forEach((elem) => {
            elem.addEventListener("click", (event) => {
                event.preventDefault();

                if ("url" in elem.dataset) {
                    let urlShare = elem.dataset.url
                    let input = document.querySelector("input#link-container");
                    if (input !== null) {
                        input.value = urlShare;
                    }
                    let clip = document.querySelector("#clipboard-copy");
                    if (clip !== null) {
                        clip.replaceWith(clip.cloneNode(true));
                        clip = document.querySelector("#clipboard-copy");
                        clip.addEventListener('click', (event) => {
                            event.preventDefault();
                            navigator.clipboard.writeText(urlShare);
                            // Alert the copied text
                            toast("Lien copié dans le presse papier !", "bg-success");
                        })
                    }
                    let fb = document.querySelector("#share-facebook");
                    if (fb !== null) {
                        fb.replaceWith(fb.cloneNode(true));
                        fb = document.querySelector("#share-facebook");
                        fb.addEventListener('click', (event) => {
                            event.preventDefault();
                            let url = facebookUrlShare.replace("{{URL}}",encodeURI(urlShare));
                            window.open(url,"blank");
                        })
                    }
                    let lkn = document.querySelector("#share-linkedin");
                    if (lkn !== null) {
                        lkn.replaceWith(lkn.cloneNode(true));
                        lkn = document.querySelector("#share-linkedin");
                        lkn.addEventListener('click', (event) => {
                            event.preventDefault();
                            let url = linkedinUrlShare.replace("{{URL}}",encodeURI(urlShare));

                            window.open(url,"blank");
                        })
                    }
                    console.log(clip,fb,lkn);
                    modal.show();
                }
            })
        })
    }
}
export default class extends Controller {

    connect() {
        super.connect();

        shareBtnEventAdder();
    }




}