import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

    connect() {
        super.connect();

        let btn = document.querySelector("#returnTopBtn");
        console.log()
        if (btn) {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                window.scrollTo({ top: 0, behavior: 'smooth' });
            })
        }
    }
}