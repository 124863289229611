import { Controller } from '@hotwired/stimulus';
const controller = (function() {
    return class LazyController extends Controller {
        constructor(context) {
            super(context);
            this.__stimulusLazyController = true;
        }
        initialize() {
            if (this.application.controllers.find((controller) => {
                return controller.identifier === this.identifier && controller.__stimulusLazyController;
            })) {
                return;
            }
            import('C:\\Htdocs\\altarimmo.fr\\assets\\controllers\\pagination_controller.js').then((controller) => {
                this.application.register(this.identifier, controller.default);
            });
        }
    }
})();
export { controller as default };